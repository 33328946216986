#app,
.app > .content {
	width: 100%;
	height: 100%;
}
html,
body {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	//font-size: 32px;
	//line-height: 44px;

	color: #1f1300;

	height: 100%;
}
body {
	text-align: right;
	margin: 0;
}

input[type='checkbox'] {
	width: 24px;

	height: 24px;
	background: #f6f8ff;
	border: 1px solid rgba(0, 78, 137, 0.5);
	border-radius: 3px;
}
input[type='time'] {
	max-width: 90%;
}
input[list],
input[type='date'],
input[type='time'],
.totalTime {
	border: solid 1px #8b8b8b !important;
	border-radius: 5px;
	padding: 5px;
}
.totalTime {
	width: 104px;
	text-align: center;
}
input,
textarea {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	//font-size: 32px;
	//line-height: 44px;
	color: #004e89;
	font-size: 1em;
}
textarea {
	display: block;
	padding: 22px;
	height: 60px;
	font-size: 15px;
	width: 100%;
	border: 1px solid #8b8b8b !important;
	border-radius: 5px;
}
#app {
	transition: background-color 0.2s ease-out;
	text-align: center;
	font-size: 1.5em;
	display: flex;
	justify-content: center;
	> .content {
		min-height: 100%;
		width: 600px;
		display: flex;
		justify-content: center;
		> div {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-content: space-evenly;
			justify-content: space-between;
		}
		> div > .content {
			margin: 1em;
		}
	}
	@media screen and (max-width: 720px) {
		> .content {
			width: 100%;
		}
	}
}
.input,
.inputs2 {
	margin-bottom: 1em;
}
.content.loading {
	filter: opacity(0.5);
}
.w80 {
	width: 80% !important;
}
.login {
	h3 {
		font-size: 24px;
	}
	main {
		font-size: 24px;
	}
	@media screen and (min-width: 900px) {
		footer {
			font-size: 26px;
		}
		h3 {
			font-size: 42px;
		}
		main {
			font-size: 32px;
		}
		footer {
			padding-top: 50px;
			padding-bottom: 50px;
			padding-left: 80px;
			padding-right: 80px;
		}
		.content {
			.logo {
				margin-top: 50px;
				margin-bottom: 100px;
			}
		}
	}

	font-size: 24px;
	// position: absolute;
	// width: 81vw;

	color: #393a56;
	text-align: right;
	.content {
		.logo {
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}
	.footer-button {
		padding-right: 15px;
		text-align: right;
	}
	footer {
		font-size: 20px;

		border-top: 1px solid #8b8b8b;
	}
}

.filling {
	.userName {
		margin-bottom: 45px;
	}
	text-align: right;

	.inputs2 > div > span,
	.input > span {
		display: inline-block;
		width: 92px;
	}
	.inputs2 {
		div {
			width: 49%;
		}

		display: flex;
	}
	/*input[type='time']/*,
	input[type='date'] {
		width: 5em;
	}*/
	.content {
		.logo {
			margin-top: 1%;
		}
	}
	* {
		outline: none;
		border: none;
		//text-decoration: underline;
	}
}
.downloadIcon {
	margin-right: 10px;
	cursor: pointer;
}
.logo {
	img {
		width: 86px;
	}
	@media screen and (min-width: 900px) {
		img {
			width: 200px;
		}
	}
	margin-bottom: 50px;
	//text-align: center;
}
.footer-button {
	padding-bottom: 5px;
	text-align: center;
	button {
		span {
			line-height: 35px;
			font-size: 24px;
		}
		font-family: 'Secular One';
		font-style: normal;
		cursor: pointer;
		width: 270px;
		height: 50px;
		color: #f6f8ff;
		outline: none;
		border: none;
		background: #3fa34d;
		border-radius: 5px;
		text-align: center;
	}
}
.clickDate {
	color: #00bcd4;
	cursor: pointer;
}

input[readonly] {
	color: #c82e2e;
}
